import React from 'react';

import * as S from './styles';

interface IfaceAlreadyRegisteredTeam {
  agent_name: string;
}

const AlreadyRegisteredTeam: React.FC<IfaceAlreadyRegisteredTeam> = (props) => {
  return (
    <S.Container style={{ textAlign: 'center' }}>
      <S.Title>CPF já está em atendimento</S.Title>
      <S.Title>
        com o corretor(a) <b>{props.agent_name}</b>,
      </S.Title>
      <S.Title>Deseja continuar o atendimento com outro corretor?</S.Title>
    </S.Container>
  );
};

export default AlreadyRegisteredTeam;
