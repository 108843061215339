import React, { useState, useEffect } from 'react';
import { ReactSVG } from 'react-svg';

import EnterpriseItem from '../../components/FacebookForm/EnterpriseItem';

import api from '../../services/emeserver/api';
import authToken from 'services/emeserver/authToken';

import { normalizeText } from '../../utils/TextFormatter';

import hmLogo from '@assets/hm-logo.svg';
import searchIcon from '@assets/search.svg';

import iEnterpriseFacebookForm from '../../dtos/iEnterpriseFacebookForm';
import iFacebookPage from '../../dtos/iFacebookPage';

import * as S from './styles';

const FacebookForm: React.FC = () => {
  const [enterprises, setEnterprises] = useState<iEnterpriseFacebookForm[]>([]);
  const [enterprisesOrinal, setEnterprisesOrinal] = useState<
    iEnterpriseFacebookForm[]
  >([]);
  const [enterpriseSearched, setEnterpriseSearched] = useState('');
  const [pages, setPages] = useState<iFacebookPage[]>([]);

  const fetchEnterprisesData = async () => {
    try {
      const res = await api.get(`enterprises/form/list`, {
        headers: {
          Authorization: authToken.token,
        },
      });

      if (res.status === 200) {
        const enterprisesRes: iEnterpriseFacebookForm[] =
          res.data.response_data;

        setEnterprises(enterprisesRes);
        setEnterprisesOrinal(enterprisesRes);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchFacebookPagesData = async () => {
    try {
      const res = await api.get(`facebook/pages/list`, {
        headers: {
          Authorization: authToken.token,
        },
      });

      if (res.status === 200) {
        const facebookPagesRes: iFacebookPage[] = res.data.response_data;

        setPages(facebookPagesRes);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleRefreshOnSave = () => {
    fetchEnterprisesData();
  };

  const handleSearchEnterpriseValue = (e) => {
    const searchedValue = e.target.value;

    setEnterpriseSearched(searchedValue);

    if (searchedValue !== '') {
      const filteredEnterprises = enterprisesOrinal.filter((enterprise) =>
        normalizeText(enterprise.name).includes(normalizeText(searchedValue)),
      );

      setEnterprises(filteredEnterprises);
    } else {
      setEnterprises(enterprisesOrinal);
    }
  };

  useEffect(() => {
    fetchEnterprisesData();
    fetchFacebookPagesData();
  }, []);

  return (
    <S.Container>
      <S.Header>
        <S.LogoContainer>
          <S.Logo src={hmLogo} alt="Logo" />

          <S.HeaderTitle>
            <strong>Formulários</strong> Facebook
          </S.HeaderTitle>
        </S.LogoContainer>

        <S.SearchContainer>
          <S.SearchInput
            placeholder="Pesquisar um empreendimento"
            onChange={(e) => handleSearchEnterpriseValue(e)}
            value={enterpriseSearched}
          />

          <ReactSVG src={searchIcon} className="search-icon" />
        </S.SearchContainer>
      </S.Header>

      <S.Body>
        {pages.length > 0 &&
          enterprises.map((enterprise) => (
            <EnterpriseItem
              key={enterprise.enterprise_id}
              enterprise_id={enterprise.enterprise_id}
              name={enterprise.name}
              facebook_forms={enterprise.facebook_forms}
              pages={pages}
              refreshEnterpriseOnSave={handleRefreshOnSave}
            />
          ))}

        {enterprises.length === 0 && (
          <S.NoEnterpriseMessage>
            Nenhum empreendimento encontrado
          </S.NoEnterpriseMessage>
        )}
      </S.Body>
    </S.Container>
  );
};

export default FacebookForm;
