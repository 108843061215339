import React, { FunctionComponent, useEffect, useRef } from 'react';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import ReactDOM from 'react-dom';

import {
  BoxModal,
  Header,
  Content,
  LoadingContainer,
  CloseButton,
  Backdrop,
  Messages,
} from './styles';
import { useState } from 'react';
import { callStatusCodeToHexColor } from 'utils/callStatusColors';
import { statusCodeToStatusLabel } from 'utils/callStatus';
import api from 'services/emeserver/api';
import authToken from 'services/emeserver/authToken';
import { Spinner } from 'react-bootstrap';
import { FiXCircle } from 'react-icons/fi';
import Select from 'components/Select';

export interface ModalProps {
  isShown: boolean;
  hide: () => void;
  actualStatus?: string;
  conversationId?: string;
}

interface ConversationMessageProps {
  from: string;
  message: string;
  time: string;
  day: string;
  sales?: boolean;
  createdAt: string;
}

export const Modal: FunctionComponent<ModalProps> = ({
  isShown,
  hide,
  actualStatus,
  conversationId,
}) => {
  const [messages, setMessages] = useState([]);
  const [allMessages, setAllMessages] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState('Todos');
  const [isLoading, setIsLoading] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const selectRef = useRef(null);

  const handleMenuOpen = () => {
    setMenuOpen(!menuOpen);
  };

  const getMessages = async () => {
    const selectedAgent = JSON.parse(sessionStorage.getItem('selectedAgent'));
    const allMessages = await api.get(
      `conversation/get/messages/${conversationId}/${selectedAgent.group_dashboard}`,
      {
        headers: {
          Authorization: authToken.token,
        },
      },
    );
    const messages: ConversationMessageProps[] = allMessages.data;

    return messages;
  };

  const handleFilterDate = (date) => {
    let filteredMessages;
    if (date !== 'Todos') {
      filteredMessages = allMessages.filter(
        (message) => message.day.replace(/['"]+/g, '') === date,
      );
    } else {
      filteredMessages = allMessages;
    }
    setSelectedOptions(date);
    setMessages(filteredMessages);
  };

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  const groupMessagesByDay = (messages) => {
    return messages.reduce((grouped, message) => {
      const day = message.day.replace(/['"]+/g, '');
      if (!grouped[day]) {
        grouped[day] = [];
      }
      grouped[day].push(message);
      return grouped;
    }, {});
  };

  const messagesByDay = groupMessagesByDay(messages);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const allMessages = await getMessages();
        const sortedMessages = allMessages.sort((a, b) =>
          a.createdAt.localeCompare(b.createdAt),
        );

        setAllMessages(sortedMessages);
        setMessages(sortedMessages);

        const days = sortedMessages.map((message) =>
          message.day.replace(/['"]+/g, ''),
        );
        const uniqueDays = Array.from(new Set(days));
        uniqueDays.push('Todos');

        setOptions(uniqueDays);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    }
    if (isShown) {
      fetchData();
    }
  }, [isShown]);

  const modal = (
    <>
      <Backdrop />
      <BoxModal>
        <CloseButton onClick={hide} style={{ justifyContent: 'flex-end' }}>
          <FiXCircle />
        </CloseButton>
        <Header backgroundHexColor={callStatusCodeToHexColor(actualStatus)}>
          <div className="status-div">
            <span>Status: </span>
            <div className="status-info">
              <span>{statusCodeToStatusLabel(actualStatus)}</span>
            </div>
          </div>
          <div className="date-div" ref={selectRef}>
            <span>Data:</span>
            <Select
              className="select-day"
              placeholder="Todos"
              style={{
                width: '235px',
                height: '48px',
                right: '9px',
                bottom: '9px',
              }}
              menuIsOpen={menuOpen}
              onMenuOpen={handleMenuOpen}
              options={options.map((item) => {
                return {
                  value: item,
                  label: item,
                };
              })}
              onChange={(e) => {
                handleFilterDate(e.value);
              }}
            />
          </div>
        </Header>
        <Content>
          {isLoading ? (
            <LoadingContainer>
              <Spinner animation="border" />
            </LoadingContainer>
          ) : (
            Object.keys(messagesByDay).map((day) => (
              <div key={day}>
                <h2 className="date">{day}</h2>
                {messagesByDay[day].map((text, index) => (
                  <Messages
                    key={index}
                    className="message-block"
                    salesTeam={text.sales}
                  >
                    <span className="date">[{text.time}]</span>
                    <div>
                      <span className="author">{text.from}: </span>
                      <span className="message">{text.message}</span>
                    </div>
                  </Messages>
                ))}
              </div>
            ))
          )}
        </Content>
      </BoxModal>
    </>
  );

  return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};

export const useModal = () => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const toggle = () => setIsShown(!isShown);
  return {
    isShown,
    toggle,
  };
};
