import React, { FormEvent, useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useHistory } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import hmLogo from '@assets/hm-logo.svg';
import api from '../../services/emeserver/api';
import authToken from '../../services/emeserver/authToken';
import * as S from './styles';
import { toast, ToastContainer } from 'react-toastify';
import Select from 'components/Select';

export default function ChangePassword(): JSX.Element {
  const [name, setName] = useState('');
  const [plantoes, setPlantoes] = useState([]);
  const [selectedPlantao, setSelectedPlantao] = useState(null);
  const [agentsTeams, setAgentsTeams] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState(null);

  const history = useHistory();

  useEffect(() => {
    async function getListTeam() {
      try {
        const resTeam = await api.get(`/agents/teams/getPlantoes`, {
          headers: {
            Authorization: authToken.token,
          },
        });

        const formattedPlantoes = resTeam.data.response_data.map((team) => ({
          label: team.label,
          value: team.value,
        }));

        setPlantoes(formattedPlantoes);
      } catch (error) {
        console.error('Erro ao buscar lista de plantões', error);
      }
    }

    getListTeam();
  }, []);

  useEffect(() => {
    async function getAgentsTeams() {
      try {
        const resTeam = await api.get(`/agents/teams/agents`, {
          headers: {
            Authorization: authToken.token,
          },
        });

        const agentsTeams = resTeam.data.response_data.map((team) => ({
          label: team.full_name,
          value: team.id,
        }));

        setAgentsTeams(agentsTeams);
      } catch (error) {
        console.error('Erro ao buscar lista de plantões', error);
      }
    }

    getAgentsTeams();
  }, []);

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    if (selectedAgents.length <= 0) {
      return toast.error('O Agente precisa ser preenchido', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    const data = {
      corretor: selectedAgents,
      team: selectedPlantao,
    };

    try {
      const response = await api.post('agents/team/agents', data, {
        headers: {
          Authorization: authToken.token,
        },
      });

      if (response.status === 200) {
        return history.push('/home');
      }
    } catch (error) {
      return toast.error(error.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  return (
    <S.Container>
      <S.Card>
        <S.LogoContainer>
          <ReactSVG src={hmLogo} />
          <h1>
            Dashboard <span>Leads</span>
          </h1>
        </S.LogoContainer>
        <h2>Vincular Agente no Plantão</h2>
        <S.Form>
          <S.InputsLabel>Plantão</S.InputsLabel>

          <Select
            value={plantoes.find((item) => item.value === selectedPlantao)}
            onChange={(selectedOption) => {
              if (selectedOption) {
                setSelectedPlantao(selectedOption.value); // Pega o 'value' da opção selecionada
              }
            }}
            options={plantoes}
            style={{
              width: '24vw',
              height: '5.5rem',
              marginBottom: '10px',
              border: 'none',
              backgroundColor: '#f3f3f3',
              fontSize: '1.2rem',
              color: '#f3f3f3',
            }}
            placeholder="Selecione um plantão"
          />

          <S.InputsLabel>Agente</S.InputsLabel>
          <Select
            value={agentsTeams.find((item) => item.value === selectedAgents)}
            onChange={(selectedOption) => {
              if (selectedOption) {
                setSelectedAgents(selectedOption.value); // Pega o 'value' da opção selecionada
              }
            }}
            options={agentsTeams}
            style={{
              width: '24vw',
              height: '5.5rem',
              marginBottom: '10px',
              border: 'none',
              backgroundColor: '#f3f3f3',
              fontSize: '1.2rem',
              color: '#f3f3f3',
            }}
            placeholder="Selecione um plantão"
          />

          <div style={{ marginTop: '4rem' }}>
            <S.ButtonContainer
              onClick={handleSubmit}
              style={{ marginRight: '10px' }}
            >
              <span>Cadastrar</span>
            </S.ButtonContainer>

            <S.ButtonContainer onClick={() => history.push('/home')}>
              <span>Cancelar</span>
            </S.ButtonContainer>
          </div>
        </S.Form>
      </S.Card>
      <ToastContainer />
    </S.Container>
  );
}
