import React, { useState, useEffect } from 'react';

import api from '@services/emeserver/api';

import iFacebookForm from '../../../dtos/iFacebookForm';
import iFacebookPage from '../../../dtos/iFacebookPage';

import * as S from './styles';

interface iFormOptionProps {
  page_id: string;
  form_id: string;
  pages: iFacebookPage[];
  action_status: string;
  updateStatusToSave(
    page_id: string,
    form_id: string,
    last_form_id: string,
  ): void;
  updateStatusToAdd(): void;
}

const FormOption: React.FC<iFormOptionProps> = ({
  page_id,
  form_id,
  pages,
  action_status,
  updateStatusToSave,
  updateStatusToAdd,
}) => {
  const [selectedPageId, setSelectedPageId] = useState('');
  const [selectedFormId, setSelectedFormId] = useState('');
  const [forms, setForms] = useState<iFacebookForm[]>([]);
  const [actionStatus, setActionStatus] = useState('add');

  useEffect(() => {
    setActionStatus(action_status);
  }, [action_status]);

  const fetchFormsByPage = async (
    page_id: string,
    page_access_token: string,
  ) => {
    try {
      const res = await api.get(
        `facebook/forms/search?page_id=${page_id}&page_access_token=${page_access_token}`,
      );

      if (res.status === 200) {
        const formsRes: iFacebookForm[] = res.data.response_data;

        const activeForms = formsRes.filter((form) => form.status === 'ACTIVE');

        setForms(activeForms);

        //popup
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelectPage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const pageIdSelected = e.target.value;

    setSelectedPageId(pageIdSelected);

    const pageFiltered = pages.find((page) => page.id === pageIdSelected);

    if (pageFiltered) {
      const page_access_token = pageFiltered.access_token;

      fetchFormsByPage(pageIdSelected, page_access_token);
    }

    updateStatusToAdd();
  };

  const handleSelectForm = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const formIdSelected = e.target.value;

    if (formIdSelected !== '') {
      const lastSelectedFormId = selectedFormId;

      if (lastSelectedFormId !== '') {
        updateStatusToSave(selectedPageId, formIdSelected, lastSelectedFormId);
      } else {
        updateStatusToSave(selectedPageId, formIdSelected, '');
      }

      setSelectedFormId(formIdSelected);
    }
  };

  const handlePageSelectedOnLoad = async () => {
    if (page_id !== '') {
      const pageFiltered = pages.find((page) => page.id === page_id);

      if (pageFiltered) {
        const page_access_token = pageFiltered.access_token;

        fetchFormsByPage(page_id, page_access_token);
      }

      setSelectedPageId(page_id);
    }
  };

  const handleFormSelectedOnLoad = async () => {
    if (form_id !== '') {
      if (forms.length > 0) {
        const formFiltered = forms.find((form) => form.id === form_id);

        if (formFiltered) {
          setSelectedFormId(formFiltered.id);
        }
      }
    }
  };

  useEffect(() => {
    handlePageSelectedOnLoad();
  }, []);

  useEffect(() => {
    handleFormSelectedOnLoad();
  }, [forms]);

  return (
    <S.Container>
      <S.Page>
        <S.PageLabel>Página</S.PageLabel>

        <S.PageSelect
          onChange={(e) => handleSelectPage(e)}
          value={selectedPageId}
          disabled={actionStatus === 'edit'}
        >
          <option value="" hidden>
            Selecione uma página
          </option>

          {pages.map((page) => (
            <option key={page.id} value={page.id}>
              {page.name}
            </option>
          ))}
        </S.PageSelect>
      </S.Page>

      <S.Form>
        {forms.length === 0 ? (
          <S.NoFormsLabel>
            Nenhum formulário encontrado para a página selecionada.
          </S.NoFormsLabel>
        ) : (
          <>
            <S.FormLabel>Formulário</S.FormLabel>

            <S.FormSelected
              onChange={(e) => handleSelectForm(e)}
              value={selectedFormId}
              disabled={actionStatus === 'edit'}
            >
              <option value="" hidden>
                Selecione um formulário
              </option>

              {forms.map((form) => (
                <option key={form.id} value={form.id}>
                  {form.name}
                </option>
              ))}
            </S.FormSelected>
          </>
        )}
      </S.Form>
    </S.Container>
  );
};

export default FormOption;
