import React, { useState, useEffect, useCallback } from 'react';
import * as S from './styles';
import { ToastContainer } from 'react-toastify';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { TableContent, CloseButton, CloseButtonLabel } from './styles';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';

interface Props {
  calls: any;
  agent_id: any;
}

const RankingDetails = (props: Props) => {
  const [entepriseValues, setEntepriseValues] = useState<any>([]);
  const [statusValues, setStatusValues] = useState<any>([]);
  const [clientsValues, setClientsValues] = useState<any>([]);
  const [statusInfo, setStatusInfo] = useState<any>({});
  const [enterpriseInfo, setEnterpriseInfo] = useState<any>({});
  const [callEnterpriseModal, setCallEnterpriseModal] =
    useState<boolean>(false);
  const [callClientsModal, setCallClientsModal] = useState<boolean>(false);

  const handleClickEnterprise = (event) => {
    setCallEnterpriseModal(true);
    setStatusInfo(event);
  };

  const handleClickCloseEnterprise = () => {
    setCallEnterpriseModal(false);
  };

  const handleClickClients = (event) => {
    setCallClientsModal(true);
    setEnterpriseInfo(event);
  };

  const handleClickCloseClients = () => {
    setCallClientsModal(false);
  };

  const handleStatus = () => {
    const agentCalls = props.calls.filter(
      (agent) => agent.agent_id === props.agent_id.id,
    );
    const callsStatus = agentCalls.map((call) => call.status);

    const result = {};
    callsStatus.forEach(function (x) {
      result[x] = (result[x] || 0) + 1;
    });

    const resultArray = Object.keys(result).map((key) => ({
      status: key,
      statusNumber: key,
      quantity: result[key],
    }));
    resultArray.forEach(function (x) {
      switch (x.status) {
        case '1':
          x.status = 'Novo';
          break;

        case '2':
          x.status = 'Em atendimento';
          break;

        case '3':
          x.status = 'Vendido';
          break;

        case '4':
          x.status = 'Descartado';
          break;

        case '5':
          x.status = 'Frio';
          break;

        case '6':
          x.status = 'Aguardando';
          break;

        case '7':
          x.status = 'Com Interesse';
          break;

        case '8':
          x.status = 'Processo de Compra';
          break;

        case '9':
          x.status = 'Retorno';
          break;

        case '10':
          x.status = 'Sem Score';
          break;
      }
    });

    setStatusValues(resultArray);
  };

  const handleEnterprise = () => {
    const agentCalls = props.calls.filter(
      (agent) => agent.agent_id === props.agent_id.id,
    );
    const entepriseName = agentCalls.filter(
      (call) => call.status === statusInfo.statusNumber,
    );
    const enterpiseNumber = entepriseName.map(
      (enteprise) => enteprise.enterprise_name,
    );

    const result = {};
    enterpiseNumber.forEach(function (x) {
      result[x] = (result[x] || 0) + 1;
    });

    const resultArray = Object.keys(result).map((key) => ({
      name: key,
      quantity: result[key],
    }));

    setEntepriseValues(resultArray);
  };

  const handleClients = () => {
    const agentCalls = props.calls.filter(
      (agent) => agent.agent_id === props.agent_id.id,
    );
    const entepriseName = agentCalls.filter(
      (enteprise) => enteprise.enterprise_name === enterpriseInfo.name,
    );
    const callStatus = entepriseName.filter(
      (call) => call.status === statusInfo.statusNumber,
    );
    const result = {};
    callStatus.forEach(function (x) {
      result[x] = (result[x] || 0) + 1;
    });

    setClientsValues(callStatus);
  };

  useEffect(() => {
    handleEnterprise();
    handleStatus();
    handleClients();
  }, [props.calls, callEnterpriseModal, callClientsModal]);

  return (
    <>
      <S.Container>
        <S.Card>
          {callEnterpriseModal === true && (
            <CloseButton
              onClick={
                callClientsModal
                  ? handleClickCloseClients
                  : handleClickCloseEnterprise
              }
            >
              <CloseButtonLabel>Voltar</CloseButtonLabel>
            </CloseButton>
          )}
          <TableContent style={{ width: '100%', height: '100%' }}>
            <TableContainer component={Paper}>
              {callEnterpriseModal === false ? (
                <Table id="table-to-xls">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          width: '8%',
                          minWidth: 500,
                          textAlign: 'left',
                        }}
                      >
                        {' '}
                        Status{' '}
                      </TableCell>
                      <TableCell
                        style={{
                          width: '8%',
                          minWidth: 500,
                          textAlign: 'left',
                        }}
                      >
                        {' '}
                        Quantidade de Atendimentos{' '}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {statusValues.map((item, i) => {
                      return (
                        <TableRow>
                          <TableCell
                            key={i}
                            style={{
                              textAlign: 'left',
                              color: '#007bff',
                              cursor: 'pointer',
                              textDecoration: 'underline',
                            }}
                            onClick={() => handleClickEnterprise(item)}
                            scope="row"
                          >
                            {' '}
                            {item.status}
                          </TableCell>
                          <TableCell style={{ textAlign: 'left' }} scope="row">
                            {' '}
                            {item.quantity}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              ) : callClientsModal === false ? (
                <Table id="table-to-xls">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          width: '8%',
                          minWidth: 500,
                          textAlign: 'left',
                        }}
                      >
                        {' '}
                        Nome do Empreendimento{' '}
                      </TableCell>
                      <TableCell
                        style={{
                          width: '8%',
                          minWidth: 500,
                          textAlign: 'left',
                        }}
                      >
                        {' '}
                        Quantidade de Atendimentos{' '}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {entepriseValues.map((item) => {
                      return (
                        <TableRow>
                          <TableCell
                            style={{
                              textAlign: 'left',
                              color: '#007bff',
                              cursor: 'pointer',
                              textDecoration: 'underline',
                            }}
                            onClick={() => handleClickClients(item)}
                            scope="row"
                          >
                            {item.name}{' '}
                          </TableCell>
                          <TableCell scope="row" style={{ textAlign: 'left' }}>
                            {' '}
                            {item.quantity}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              ) : (
                <Table id="table-to-xls">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          width: '370px',
                          minWidth: 370,
                          textAlign: 'left',
                        }}
                      >
                        {' '}
                        Nome do Cliente{' '}
                      </TableCell>
                      <TableCell
                        style={{
                          width: '150px',
                          minWidth: 150,
                          textAlign: 'left',
                        }}
                      >
                        {' '}
                        Telefone{' '}
                      </TableCell>
                      <TableCell
                        style={{
                          width: '165px',
                          minWidth: 165,
                          textAlign: 'left',
                        }}
                      >
                        {' '}
                        Data de Criação{' '}
                      </TableCell>
                      {statusInfo.statusNumber === '3' ? (
                        <TableCell
                          style={{
                            width: '170px',
                            minWidth: 170,
                            textAlign: 'left',
                          }}
                        >
                          {' '}
                          Data de Venda{' '}
                        </TableCell>
                      ) : (
                        <TableCell
                          style={{
                            width: '165px',
                            minWidth: 165,
                            textAlign: 'left',
                          }}
                        >
                          {' '}
                          Data de Atualização{' '}
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {clientsValues.map((item) => {
                      var updatedDate = new Date(
                        item.call_date_update_original,
                      );
                      var updatedDateFormated = updatedDate
                        .toLocaleString()
                        .slice(0, -3);
                      return (
                        <TableRow>
                          <TableCell style={{ textAlign: 'left' }} scope="row">
                            {item.full_name}{' '}
                          </TableCell>
                          <TableCell scope="row" style={{ textAlign: 'left' }}>
                            {' '}
                            {item.phone_number}
                          </TableCell>
                          <TableCell scope="row" style={{ textAlign: 'left' }}>
                            {' '}
                            {item.call_date}
                          </TableCell>
                          {statusInfo.statusNumber === '3' ? (
                            <TableCell
                              scope="row"
                              style={{ textAlign: 'left' }}
                            >
                              {' '}
                              {updatedDateFormated}
                            </TableCell>
                          ) : (
                            <TableCell
                              scope="row"
                              style={{ textAlign: 'left' }}
                            >
                              {' '}
                              {updatedDateFormated}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </TableContent>
        </S.Card>
        <ToastContainer />
      </S.Container>
    </>
  );
};

export default RankingDetails;
