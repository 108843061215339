import axios from 'axios';

const { REACT_APP_API_URL } = process.env;

const api = axios.create({
  baseURL: 'https://eme-server.devhagens.com.br/',
  //baseURL: 'https://emeserver.maishm.com.br/',
});

export default api;
