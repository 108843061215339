import api from 'services/emeserver/api';
import authToken from 'services/emeserver/authToken';
import { Filters } from 'utils/InterFaces';

export const new_calls_find = async (params: Filters) => {
  const req = await api.get('/dashboard/new/calls/find', {
    params,
    headers: {
      Authorization: authToken.token,
    },
  });

  return {
    clients: req.data.response_data.dashboard_data.clients,
    callsForRedistribute:
      req.data.response_data.dashboard_data.callsForRedistribute,
  };
};
