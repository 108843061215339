import React, { useState, useMemo } from 'react';
import { ReactSVG } from 'react-svg';
import { Spinner } from 'react-bootstrap';
import {
  FaUserPlus,
  FaUserTie,
  FaUsers,
  FaTable,
  FaUnlock,
  FaUserMinus,
} from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import { getInitalByFullname } from '../../utils/nameTreatment';
import api from '../../services/emeserver/api';
import authToken from '../../services/emeserver/authToken';

import playIcon from '../../assets/play-icon.svg';
import logoutIcon from '../../assets/logout-icon.svg';
import pauseIcon from '../../assets/pause-icon.svg';
import alertIcon from '../../assets/alert-icon.svg';
import { ButtonsContainer } from './styles';
import * as S from './styles';

interface iProfileProps {
  agent_id: number;
  name: string;
  available: boolean;
  permission: string;
  selectAgent: any;
  handleCloseProfile(available: boolean): void;
}

const Profile: React.FC<iProfileProps> = ({
  agent_id,
  name,
  available: initialAvailable,
  permission,
  selectAgent,
  handleCloseProfile,
}) => {
  const history = useHistory();
  const [available, setAvailable] = useState(initialAvailable);
  const [showLoading, setShowLoading] = useState(false);
  const plantao = sessionStorage.getItem('group');

  const ruleImobs =
    selectAgent.group_dashboard.includes('eme_plantao') &&
    selectAgent.permission.includes('corretor');

  const ruleAdminImobs =
    selectAgent.group_dashboard.includes('eme_plantao') &&
    selectAgent.permission.includes('admin');

  const handleChangeAvailability = async () => {
    const updatedAvailability = !available;
    setAvailable(updatedAvailability);
    setShowLoading(true);

    setTimeout(async () => {
      await api.put(
        '/agents/change/availability',
        { agent_id, availability: updatedAvailability },
        { headers: { Authorization: authToken.token } },
      );
      sessionStorage.setItem('available', JSON.stringify(updatedAvailability));
      setShowLoading(false);
    }, 1500);
  };

  const handleLogout = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowLoading(true);

    await api.put(
      '/agents/change/availability',
      { agent_id, availability: false },
      { headers: { Authorization: authToken.token } },
    );

    await api.post(
      '/agents/logout',
      { agent_id },
      { headers: { Authorization: authToken.token } },
    );

    sessionStorage.clear();
    history.push('/');
  };

  const navigateTo = (path: string) => {
    history.push(path);
  };

  const renderButton = (
    label: string,
    icon: JSX.Element,
    path: string,
    hidden = false,
  ) => (
    <S.RoundedButton
      style={{ marginRight: '15px' }}
      type="button"
      onClick={() => navigateTo(path)}
      hidden={hidden}
    >
      {icon}
      <S.RoundedButtonLabel>{label}</S.RoundedButtonLabel>
    </S.RoundedButton>
  );
  const defaultButtons = useMemo(
    () => [
      {
        label: 'Adicionar Contato',
        icon: <FaUserPlus size={22} color="#FFF" />,
        path: '/register/contact',
        hidden: ruleImobs || ruleAdminImobs,
      },
      {
        label: 'Adicionar Lead',
        icon: <FaUserPlus size={22} color="#FFF" />,
        path: '/register/lead',
        hidden: false,
      },
      {
        label: 'Alterar Senha',
        icon: <FaUnlock size={22} color="#FFF" />,
        path: '/alterar/senha',
        hidden: false,
      },
    ],
    [ruleImobs],
  );

  const adminButtons = useMemo(
    () => [
      ...defaultButtons,
      {
        label: 'Status Equipe',
        icon: <FaUsers size={22} color="#FFF" />,
        path: '/team/status',
        hidden: ruleImobs || ruleAdminImobs,
      },
      {
        label: 'Ranking Equipe',
        icon: <FaTable size={22} color="#FFF" />,
        path: '/team/ranking',
        hidden: ruleImobs || ruleAdminImobs,
      },
      {
        label: 'Adicionar Agente',
        icon: <FaUserTie size={22} color="#FFF" />,
        path: '/register',
        hidden: ruleImobs,
      },
      {
        label: 'Desativar Agente',
        icon: <FaUserMinus size={22} color="#FFF" />,
        path: '/desativar/agent',
        hidden: ruleImobs || ruleAdminImobs,
      },
      {
        label: 'Adicionar Plantão',
        icon: <FaUsers size={22} color="#FFF" />,
        path: '/register/plantao',
        hidden: ruleImobs,
      },
      {
        label: 'Adicionar Agente ao Plantão',
        icon: <FaUsers size={22} color="#FFF" />,
        path: '/register/plantao/agent',
        hidden: ruleImobs,
      },
    ],
    [ruleImobs],
  );

  const corretorButtons = useMemo(
    () => [
      ...defaultButtons,
      {
        label: 'Ranking Pessoal',
        icon: <FaTable size={22} color="#FFF" />,
        path: '/team/ranking',
        hidden: ruleImobs,
      },
    ],
    [ruleImobs],
  );
  return (
    <S.Container onMouseLeave={() => handleCloseProfile(available)}>
      <S.AgentContainer>
        <S.AgentAvatar>
          <span>{getInitalByFullname(name)}</span>
          <S.AvailabilityIndicator available={available} />
        </S.AgentAvatar>
        <S.AgentName>
          {name}
          <br />
          {plantao}
        </S.AgentName>
      </S.AgentContainer>

      {(permission === 'admin' || permission === 'admin-super') && (
        <ButtonsContainer columns={2}>
          {adminButtons.map((btn, index) =>
            renderButton(btn.label, btn.icon, btn.path, btn.hidden),
          )}
        </ButtonsContainer>
      )}

      {permission === 'eme_plantao' && (
        <ButtonsContainer columns={2}>
          {defaultButtons.map((btn, index) => {
            return renderButton(btn.label, btn.icon, btn.path, btn.hidden);
          })}
        </ButtonsContainer>
      )}

      {permission === 'corretor' && (
        <>
          <ButtonsContainer columns={2}>
            {corretorButtons.map((btn, index) =>
              renderButton(btn.label, btn.icon, btn.path, btn.hidden),
            )}
          </ButtonsContainer>
          <S.AvailabilityContainer>
            <S.AvailabilityLabel>
              {available
                ? 'Desative seu perfil para novos leads'
                : 'Re-ative seu perfil para novos leads'}
            </S.AvailabilityLabel>
            <S.AvailabilityButton
              available={available}
              onClick={handleChangeAvailability}
              isLoading={showLoading}
              disabled={showLoading}
            >
              <S.AvailabilityButtonIndicador
                available={available}
                isLoading={showLoading}
              >
                <ReactSVG src={available ? playIcon : pauseIcon} />
              </S.AvailabilityButtonIndicador>
            </S.AvailabilityButton>
          </S.AvailabilityContainer>
        </>
      )}

      {showLoading && (
        <S.LoadingContainer>
          <Spinner animation="border" />
        </S.LoadingContainer>
      )}

      {!available && !showLoading && permission === 'corretor' && (
        <S.AlertTurnOffContainer>
          <ReactSVG src={alertIcon} />
          <S.AlertTurnOffMessage>
            Seu perfil está desativado para receber novos leads. Re-ative para
            participar da roleta de leads normalmente.
          </S.AlertTurnOffMessage>
        </S.AlertTurnOffContainer>
      )}

      <S.LogoutButton
        onClick={handleLogout}
        isLoading={showLoading}
        disabled={showLoading}
      >
        <ReactSVG src={logoutIcon} />
        <S.LogoutLabel>Logout</S.LogoutLabel>
      </S.LogoutButton>
    </S.Container>
  );
};

export default Profile;
