import React from 'react';
import { ReactSVG } from 'react-svg';
import { useHistory } from 'react-router-dom';

import checkIcon from '../../../assets/check.svg';

import * as S from './styles';

interface ISuccessProps {
  agent_name?: string;
  permission?: string;
}

const Success: React.FC<ISuccessProps> = (props) => {
  const history = useHistory();

  function goReturn() {
    if (props.permission === 'eme_plantao') {
      window.location.href = '/register/team';
    } else {
      history.goBack();
    }
  }
  return (
    <S.Container>
      <ReactSVG src={checkIcon} />

      <S.SuccessTitle>Lead cadastrado com sucesso</S.SuccessTitle>

      <S.Information>
        O Lead estará disponivel na dashboard do corretor
        {props.agent_name && (
          <>
            , <b>{props.agent_name}</b>
          </>
        )}
      </S.Information>

      <S.CloseButton
        onClick={() => {
          goReturn();
        }}
      >
        Fechar
      </S.CloseButton>
    </S.Container>
  );
};

export default Success;
