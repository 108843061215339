import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';
import {
  Content,
  Table,
  TBody,
  THead,
  CloseButton,
  CloseButtonLabel,
} from '../../components/TableAgents/styles';
import api from 'services/emeserver/api';
import authToken from 'services/emeserver/authToken';

import * as S from './styles';

export default function TeamStatus() {
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState('');
  const [totalClients, setTotalClients] = useState(0);

  const history = useHistory();

  const agent = JSON.parse(sessionStorage.getItem('selectedAgent'));

  async function getAllAgentsWithTotal() {
    const agentsResponse = await api.get('/agents/team/status', {
      headers: {
        Authorization: authToken.token,
      },
    });

    if (agentsResponse.status === 200) {
      const agents = agentsResponse.data.response_data.agents;
      const total_clients = agentsResponse.data.response_data.total_clients;

      return {
        total_clients,
        agents,
      };
    }
  }

  useEffect(() => {
    if (
      sessionStorage.getItem('permission') !== 'admin' &&
      sessionStorage.getItem('permission') !== 'admin-super'
    ) {
      history.push('/home');
    }
    async function setAllAgentsAndTotal() {
      const { agents, total_clients } = await getAllAgentsWithTotal();

      if (agents.length > 0) {
        let updatedCalls = agents.filter((agents) => {
          if (agent.group_dashboard === 'hmgeral') {
            return agents;
          } else {
            return agents.group_dashboard === agent.group_dashboard;
          }
        });
        setAgents(updatedCalls);
        setTotalClients(total_clients);
      }
    }

    setAllAgentsAndTotal();
  }, []);

  function handleCloseButton() {
    history.goBack();
  }

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      history.goBack();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  const handleUpdateStatusAgent = async (
    agent_id: number,
    available: boolean,
  ) => {
    const agentResponse = await api.put(
      '/agents/team/update/status',
      {
        agent_id,
        availability: !available,
      },
      {
        headers: {
          Authorization: authToken.token,
        },
      },
    );

    if (agentResponse.data.status === 'success') {
      const filter = agents.map((item) => {
        if (item.id === agent_id) {
          return {
            ...item,
            available: !available,
          };
        } else {
          return item;
        }
      });

      setAgents(filter);
    }
  };

  return (
    <S.Container>
      <S.AgentsContainer>
        <S.Header>
          <S.Title>Status Equipe</S.Title>

          <S.CloseButton onClick={handleCloseButton}>
            <S.CloseButtonLabel>Fechar</S.CloseButtonLabel>
          </S.CloseButton>
        </S.Header>

        <S.Agents>
          <Table style={{ minWidth: 0 }}>
            <THead>
              <tr>
                <th>Status</th>
                <th>Agentes</th>
                <th>Grupo</th>
              </tr>
            </THead>
            <TBody>
              {agents.map((agent, i) => (
                <tr key={i}>
                  <td align="center">
                    <S.Score
                      available={agent.available}
                      number_of_leads={10}
                    ></S.Score>
                  </td>
                  <td align="center">
                    <S.Name>
                      <button
                        onClick={() => {
                          handleUpdateStatusAgent(agent.id, agent.available);
                        }}
                      >
                        {agent.full_name}
                      </button>
                      <span>Clique para mudar status do corretor</span>
                    </S.Name>
                  </td>
                  <td align="center">{agent.group_dashboard}</td>
                </tr>
              ))}
            </TBody>
          </Table>
        </S.Agents>
      </S.AgentsContainer>
    </S.Container>
  );
}
