import React, { FormEvent, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useHistory } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-bootstrap/Modal';

import hmLogo from '@assets/hm-logo.svg';
import api from '../../services/emeserver/api';

import * as S from './styles';
import { toast, ToastContainer } from 'react-toastify';

export default function Login(): JSX.Element {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [plantoes, setPlantoes] = useState([]);
  const [selectedPlantao, setSelectedPlantao] = useState(null);

  const history = useHistory();

  // Função utilitária para exibir mensagens
  const showToast = (message: string, type: 'success' | 'error') => {
    toast[type](message, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  // Função para buscar plantões
  const fetchPlantoes = async (token: string) => {
    try {
      const response = await api.get('/agents/teams/plantoesbyagents', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPlantoes(response.data.response_data); // Atualiza a lista de plantões
    } catch (err) {
      console.error('Erro ao buscar plantões:', err);
      showToast('Erro ao carregar plantões', 'error');
    }
  };

  // Função para processar login
  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    if (!email) return showToast('O email precisa ser preenchido', 'error');
    if (!password) return showToast('A senha precisa ser preenchida', 'error');

    try {
      const response = await api.post('agents/login', { email, password });

      if (response.status === 200) {
        const data = response.data;
        sessionStorage.setItem('token', data.token);
        sessionStorage.setItem('user', email);
        sessionStorage.setItem('permission', data.permission);
        sessionStorage.setItem('selectedAgent', JSON.stringify(data));
        sessionStorage.setItem('available', JSON.stringify(data.available));

        if (data.permission === 'admin-facebook') {
          history.push('/facebook/forms/home');
        } else if (
          data.group_dashboard === 'eme_plantao' &&
          data.permission === 'corretor'
        ) {
          await fetchPlantoes(data.token);
          setIsModalOpen(true);
        } else if (
          data.group_dashboard === 'eme_plantao' &&
          data.permission === 'eme_plantao'
        ) {
          window.location.href = '/register/team';
        } else {
          window.location.href = '/home';
        }
      }
    } catch (error) {
      showToast('Erro ao tentar fazer login', 'error');
    }
  }

  // Confirma a seleção do plantão
  const handleConfirmPlantao = () => {
    if (!selectedPlantao) return showToast('Selecione um plantão!', 'error');

    sessionStorage.setItem('group', selectedPlantao);
    setIsModalOpen(false); // Fecha modal
    window.location.href = '/home';
  };

  return (
    <S.Container>
      <S.Card>
        <S.Form>
          <S.LogoContainer>
            <ReactSVG src={hmLogo} />
            <h1>
              Dashboard <span>Leads</span>
            </h1>
          </S.LogoContainer>

          <S.InputsContainer>
            <S.InputContainer>
              <input
                style={{ width: '100%' }}
                type="email"
                placeholder="E-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </S.InputContainer>

            <S.InputContainer style={{ marginTop: '30px' }}>
              <input
                placeholder="Senha"
                value={password}
                type={showPassword ? 'initial' : 'password'}
                onChange={(e) => setPassword(e.target.value)}
              />
              <S.IconContainer>
                {showPassword ? (
                  <FiEye
                    onClick={() => setShowPassword(!showPassword)}
                    size={20}
                  />
                ) : (
                  <FiEyeOff
                    onClick={() => setShowPassword(!showPassword)}
                    size={20}
                  />
                )}
              </S.IconContainer>
            </S.InputContainer>
          </S.InputsContainer>

          <S.ButtonContainer style={{ width: '50%' }} onClick={handleSubmit}>
            <span>Entrar</span>
          </S.ButtonContainer>
        </S.Form>
      </S.Card>
      <div>
        <S.EstiloModalSelecao
          show={isModalOpen}
          onHide={() => setIsModalOpen(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <b>Selecione um Plantão</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ul>
              {plantoes.map((plantao) => (
                <li key={plantao.id} className="plantao-item">
                  <input
                    type="radio"
                    name="plantao"
                    value={plantao.id}
                    onChange={() => setSelectedPlantao(plantao.team)}
                  />{' '}
                  {plantao.team}
                </li>
              ))}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={handleConfirmPlantao}>
              Confirmar Seleção
            </button>
          </Modal.Footer>
        </S.EstiloModalSelecao>
      </div>
      <ToastContainer />
    </S.Container>
  );
}
