import React, { useContext } from 'react';
import { Field } from 'formik';
import { IMaskInput } from '@mirco312312/react-imask';
import FormContext from 'context/FormContext';
import * as S from './styles';
import AlreadyRegistered from '../AlreadyRegistered';
import { capitalizeWords } from 'utils/nameTreatment';
import AlreadyRegisteredTeam from 'pages/Teams/Register/AlreadyRegistered';

interface StepOneProps {
  handleFetchRegisteredLead: Function;
  teamList: any;
  alreadyRegistered: boolean;
  agentName: string;
  plantaoList?: any;
}

const StepOne: React.FC<StepOneProps> = (props) => {
  const { fields, setFields } = useContext(FormContext);
  const selectedAgent = JSON.parse(sessionStorage.getItem('selectedAgent'));
  const permission = sessionStorage.getItem('permission');

  function changeString(value, property) {
    setFields({ ...fields, [property]: value });
  }

  function getDashboard(e, property) {
    props.handleFetchRegisteredLead();
  }

  function getCpf(e, property) {
    props.handleFetchRegisteredLead();
  }

  let componentToRender;

  if (permission === 'eme_plantao') {
    componentToRender = <AlreadyRegisteredTeam agent_name={props.agentName} />;
  } else {
    componentToRender = <AlreadyRegistered agent_name={props.agentName} />;
  }

  return (
    <S.Container>
      {props.alreadyRegistered ? (
        componentToRender
      ) : (
        <>
          <select
            name="groupDashboard"
            value={fields.group_dashboard}
            required
            onChange={(e) => changeString(e.target.value, 'group_dashboard')}
            onBlur={(e) => getDashboard(e, 'group_dashboard')}
            className="fieldStyle"
          >
            <option value="">
              Selecione{' '}
              {selectedAgent.group_dashboard != 'eme_plantao'
                ? 'a equipe'
                : 'o plantão'}
            </option>
            {selectedAgent.group_dashboard == 'eme_plantao' &&
              props.plantaoList.map((item: any, i: number) => (
                <option key={i + 1} value={item.label}>
                  {capitalizeWords(item.label)}
                </option>
              ))}
            {selectedAgent.group_dashboard != 'eme_plantao' &&
              props.teamList.map((item: any) => (
                <option key="hmsaopaulo" value="Equipe HM São Paulo">
                  {item.label}
                </option>
              ))}
          </select>
          <Field>
            {({ field }) => (
              <IMaskInput
                {...field}
                disabled={
                  selectedAgent.group_dashboard == 'eme_plantao' &&
                  fields['group_dashboard'] == ''
                    ? true
                    : false
                }
                name="cpf"
                placeholder="CPF"
                required
                mask="000.000.000-00"
                value={fields.cpf}
                unmask
                onBlur={(e) => getCpf(e, 'cpf')}
                onAccept={(value) => changeString(value, 'cpf')}
                className="fieldStyle"
              />
            )}
          </Field>

          <Field>
            {({ field }) => (
              <IMaskInput
                {...field}
                name="phone_number"
                placeholder="Telefone"
                required
                disabled={
                  selectedAgent.group_dashboard == 'eme_plantao' &&
                  fields['group_dashboard'] == ''
                    ? true
                    : false
                }
                mask="(00) 00000-0000"
                value={fields.phone_number}
                unmask
                onAccept={(value) => changeString(value, 'phone_number')}
                className="fieldStyle"
              />
            )}
          </Field>
        </>
      )}
    </S.Container>
  );
};

export default StepOne;
