import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useQuery } from 'react-query';

import api from '../../services/emeserver/api';
import ContentHeader from '../../components/ContentHeader';
import SelectInput from '../../components/SelectInput';
import { TableAgents } from '../../components/TableAgents';
import { Dropdown } from '../../components/Dropdown';
import { DashboadAgents, DashboardClients } from '../../utils/InterFaces';

import listOfMonths from '../../utils/months';
import { useHistory } from 'react-router';

import iconOrdenation from '../../assets/Icone-Ordenacao.svg';
import iconRatioUp from '../../assets/Icone-RatioAlto.svg';
import iconRatioDown from '../../assets/Icone-RatioBaixo.svg';
import iconCallsUp from '../../assets/Icone-MaisAtendimentos.svg';
import iconCallsDown from '../../assets/Icone-MenosAtendimentos.svg';
import iconSalesUp from '../../assets/Icone-MaisVenda.svg';
import iconSalesDown from '../../assets/Icone-MenosVenda.svg';

import {
  Container,
  Content,
  LineSeparator,
  Header,
  Title,
  CloseButton,
  CloseButtonLabel,
} from './styles';
const token =
  'DB20F65F9D282E869E6183503259CD84E12BCF90DC2433AE7AC41DF3A241A364';

const ListItemsOrdination = [
  { title: 'Ratio mais alto', img: iconRatioUp },
  { title: 'Ratio mais baixo', img: iconRatioDown },
  { title: 'Mais vendas', img: iconCallsUp },
  { title: 'Menos vendas', img: iconCallsDown },
  { title: 'Mais atendimentos', img: iconSalesUp },
  { title: 'Menos atendimentos', img: iconSalesDown },
];

export default function TeamRanking() {
  const getUserLogged = () => {
    const user = sessionStorage.getItem('selectedAgent');
    const userLogged = user ? JSON.parse(user) : undefined;

    return userLogged;
  };
  const { id, permission, group_dashboard } = getUserLogged();
  const [monthSelected, setMonthSelected] = useState<number>(0);
  const [yearSelected, setYearSelected] = useState<number>(0);
  const [dashboardSelected, setDashboardSelected] = useState('');
  const [status, setStatus] = useState([]);
  const [orderBy, setOrderBy] = useState('');
  const [isOrder, setIsOrder] = useState(false);
  const [groupsOptions, setGroupsOptions] = useState([]);
  const [reportAgents, setReportAgents] = useState<DashboadAgents[]>([]);
  const [clientsData, setClientsData] = useState<DashboardClients[]>([]);
  const [detailsCalls, setDetailsCalls] = useState<DashboardClients[]>([]);
  const history = useHistory();

  const months = useMemo(() => {
    const months = listOfMonths.map((month, index) => {
      return {
        value: index + 1,
        label: month,
      };
    });
    return [{ value: 0, label: 'Mês' }, ...months];
  }, []);

  const years = useMemo(() => {
    const yearCurrent = new Date().getFullYear();
    const yearInitial = 2018;

    const years = [];

    for (let i = yearInitial; i <= yearCurrent; i++) {
      years.push({
        value: i,
        label: i,
      });
    }

    years.sort((a, b) => b.value - a.value);
    return [{ value: 0, label: 'Ano' }, ...years];
  }, []);

  const handleMonthSelected = (month: string) => {
    const parseMonth = Number(month);
    setMonthSelected(parseMonth);
  };

  const handleYearSelected = (year: string) => {
    const parseYear = Number(year);
    setYearSelected(parseYear);
  };

  const handleDashboardSelected = (dashboard: string) => {
    setDashboardSelected(dashboard);
  };

  const orderByYearMonth = (param: DashboardClients[]) => {
    let ordered = param;
    if (yearSelected === 0 && monthSelected === 0) {
      return ordered;
    }
    if (yearSelected > 0) {
      ordered = ordered.filter(
        (item) =>
          new Date(item.call_date_original).getFullYear() === yearSelected,
      );
    }
    if (monthSelected > 0) {
      ordered = ordered.filter(
        (item) =>
          new Date(item.call_date_original).getMonth() + 1 === monthSelected,
      );
    }
    setIsOrder(true);
    setDetailsCalls(ordered);

    return ordered;
  };

  const handleOrderByRatio = (param: DashboadAgents[]) => {
    let orderFilter;
    switch (orderBy) {
      case 'Menos atendimentos':
        orderFilter = param.sort((a, b) => a.quantity - b.quantity);
        break;
      case 'Ratio mais baixo':
        orderFilter = param.sort((a, b) => a.ratio - b.ratio);
        break;
      case 'Mais vendas':
        orderFilter = param.sort((a, b) => b.quantitySold - a.quantitySold);
        break;
      case 'Menos vendas':
        orderFilter = param.sort((a, b) => a.quantitySold - b.quantitySold);
        break;
      case 'Mais atendimentos':
        orderFilter = param.sort((a, b) => b.quantity - a.quantity);
        break;

      default:
        orderFilter = param.sort((a, b) => b.ratio - a.ratio);
        break;
    }
    return orderFilter;
  };

  const new_calls_find = async () => {
    if (id && Number(id) > 0) {
      const res = await api.get('/dashboard/new/calls/find', {
        params: {
          agent_id: id,
          agent_permission: permission,
        },
        headers: {
          Authorization: token,
        },
      });

      const clients = res.data.response_data.dashboard_data.clients;
      const callsForRedistribute =
        res.data.response_data.dashboard_data.callsForRedistribute;
      return {
        clients,
        callsForRedistribute,
      };
    } else {
      return {
        clients: [],
        callsForRedistribute: null,
      };
    }
  };

  const getAllGroups = useCallback(async () => {
    let groupsResponse;
    if (permission === 'admin-super') {
      groupsResponse = await api.get('/dashboard/agents/getGroups', {
        headers: {
          Authorization: token,
        },
      });
    } else {
      groupsResponse = await api.get(
        '/dashboard/agents/getImobGroup/' + group_dashboard,
        {
          headers: {
            Authorization: token,
          },
        },
      );
    }

    const groups = groupsResponse.data.response_data;

    return groups;
  }, []);

  const getAgents = useCallback(async () => {
    const agentResponse = await api.get('/agents/not-admin', {
      headers: {
        Authorization: token,
      },
    });

    const response_data = agentResponse.data.agents;
    return response_data;
  }, [status]);

  useEffect(() => {
    async function setAllAgents() {
      const agentResponse = await getAgents();

      if (permission === 'corretor') {
        const currentUser = agentResponse.find((agent) => agent.id === id);
        setStatus([currentUser]);
      } else {
        setStatus(agentResponse);
      }
    }

    async function setAllGroups() {
      let groups = await getAllGroups();
      for (var i = 0; i < groups.length; i++) {
        if (groups[i].value === 'outro') {
          groups.splice(i, 1);
        } else if (groups[i].value === 'hmgeral') {
          groups.splice(i, 1);
        }
      }
      groups.unshift({ value: '', label: 'Geral' });
      groups.unshift({ value: 'hmgeral', label: 'Grupos' });

      setGroupsOptions(groups);
    }

    async function fetch() {
      await setAllAgents();
      await setAllGroups();
    }

    fetch();
  }, []);

  // useEffect(() => {
  //   const run = async () => {
  //     const agentResponse = await api.get('/agents/not-admin', {
  //       headers: {
  //         Authorization: token,
  //       },
  //     });

  //     setStatus(agentResponse.data.agents);
  //   };
  //   run();
  // }, []);

  useQuery('', () => new_calls_find(), {
    onSuccess: (result) => {
      setClientsData(result.clients);
    },
    refetchInterval: 1000 * 60 * 2, // 2 minutes
  });

  const calcRatio = (param: DashboardClients[]) => {
    const count = status.map((item) => ({
      ...item,
      quantitySold: param.filter((client) => {
        if (client.agent_id) {
          return (
            client.agent_id.toString() === item.id.toString() &&
            client.status === '3'
          );
        }
      }).length,
      quantity: param.filter((client) => {
        if (client.agent_id) {
          return client.agent_id.toString() === item.id.toString();
        }
      }).length,
    }));

    const calc = count.map((item) => ({
      ...item,
      ratio:
        item.quantitySold > 0
          ? Number(
              ((item.quantitySold / item.quantity) * 100 + 0.01).toFixed(1),
            )
          : 0,
    }));
    const sorted = calc.slice().sort((a, b) => b.ratio - a.ratio);

    return sorted;
  };

  const handleOrdination = (param: DashboardClients[]) => {
    let filter = orderByYearMonth(param);
    let count = calcRatio(filter);

    count = orderBy !== '' ? handleOrderByRatio(count) : count;
    const session = sessionStorage.getItem('selectedAgent');
    const jsonSession = JSON.parse(session);

    if (dashboardSelected !== '' && jsonSession.group_dashboard !== 'hmgeral') {
      count = count.filter(
        (agent) => String(agent.group_dashboard) === dashboardSelected,
      );
    } else if (
      jsonSession.group_dashboard !== '' &&
      dashboardSelected !== 'hmgeral' &&
      dashboardSelected !== ''
    ) {
      count = count.filter(
        (agent) => String(agent.group_dashboard) === dashboardSelected,
      );
    } else {
      count = count;
    }

    setReportAgents(count);
  };

  useEffect(() => {
    handleOrdination(clientsData); // eslint-disable-next-line
  }, [clientsData, orderBy, yearSelected, monthSelected, dashboardSelected]);

  const handleCloseButton = () => {
    history.goBack();
  };

  return (
    <Container>
      <Header>
        <Title>Ranking Equipe</Title>

        <CloseButton onClick={handleCloseButton}>
          <CloseButtonLabel>Sair</CloseButtonLabel>
        </CloseButton>
      </Header>
      <ContentHeader title="Dashboard" lineColor="#F7931B">
        <SelectInput
          options={years}
          onChange={(e) => handleYearSelected(e.target.value)}
        />
        <SelectInput
          options={months}
          onChange={(e) => handleMonthSelected(e.target.value)}
        />
        {permission === 'admin-super' && (
          <SelectInput
            options={groupsOptions}
            onChange={(e) => handleDashboardSelected(e.target.value)}
          />
        )}
        )
      </ContentHeader>
      <LineSeparator />
      <Content>
        <Dropdown
          title="Ordem de visualização"
          listItem={ListItemsOrdination}
          icon={iconOrdenation}
          show={false}
          onChange={setOrderBy}
        />
      </Content>

      <Content>
        <TableAgents
          orderBy={orderBy}
          reportAgents={reportAgents}
          calls={isOrder ? detailsCalls : clientsData}
        />
      </Content>
    </Container>
  );
}
