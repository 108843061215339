import React, { FormEvent, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useHistory } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import hmLogo from '@assets/hm-logo.svg';
import api from '../../services/emeserver/api';
import authToken from '../../services/emeserver/authToken';
import * as S from './styles';
import { toast, ToastContainer } from 'react-toastify';

export default function RegisterTeams(): JSX.Element {
  const [name, setName] = useState('');

  const history = useHistory();

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    if (name.length <= 0) {
      return toast.error('O Nome precisa ser preenchido', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    const data = {
      teams: name,
    };

    try {
      const response = await api.post('agents/team/create', data, {
        headers: {
          Authorization: authToken.token,
        },
      });

      if (response.status === 200) {
        return history.push('/register/plantao/agent');
      }
    } catch (error) {
      return toast.error(error.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  return (
    <S.Container>
      <S.Card>
        <S.LogoContainer>
          <ReactSVG src={hmLogo} />
          <h1>
            Dashboard <span>Leads</span>
          </h1>
        </S.LogoContainer>
        <h2>Cadastro Plantões</h2>
        <S.Form>
          <S.InputsLabel>Plantão</S.InputsLabel>
          <S.InputsContainer>
            <S.InputContainer
              isEmpty={name === ''}
              style={{ marginBottom: '10px' }}
            >
              <input
                style={{ width: '100%' }}
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </S.InputContainer>
          </S.InputsContainer>

          <div style={{ marginTop: '4rem' }}>
            <S.ButtonContainer
              onClick={handleSubmit}
              style={{ marginRight: '10px' }}
            >
              <span>Cadastrar</span>
            </S.ButtonContainer>

            <S.ButtonContainer onClick={() => history.push('/home')}>
              <span>Cancelar</span>
            </S.ButtonContainer>
          </div>
        </S.Form>
      </S.Card>
      <ToastContainer />
    </S.Container>
  );
}
