import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useHistory } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { Form, Formik, FormikConfig, FormikProps, FormikValues } from 'formik';
import {
  Button,
  CircularProgress,
  Step,
  StepLabel,
  Stepper,
} from '@material-ui/core';
import {
  removeSpecialCaracteres,
  removeSpecialCaractersFromPhoneNumber,
  cpfValidate,
} from 'utils/numberUtils';
import FormContext from 'context/FormContext';
import StepOne from '../../RegisterLead/StepOne';
import StepTwo from '../../RegisterLead/StepTwo';
import Success from '../../RegisterLead/Success';
import hmLogo from '@assets/hm-logo.svg';
import api from '@services/emeserver/api';
import authToken from '@services/emeserver/authToken';
import * as S from './styles';
import { toast, ToastContainer } from 'react-toastify';
import { Spinner, Modal } from 'react-bootstrap';
import { EstiloModalSelecao } from 'pages/Login/styles';

interface clientProps {
  full_name: string;
  email: string;
  agent_id: number;
  agent_name: string;
  phone_number?: string;
  cpf?: string;
}

export default function RegisterTeamLead(): any {
  const [fields, setFields] = useState({
    full_name: '',
    cpf: '',
    phone_number: '',
    email: '',
    agent_id: '',
    enterprise_slug: '',
    call_type: '',
    group_dashboard: '',
  });
  const [alreadyRegistered, setAlreadyRegistered] = useState(false);
  const [success, setSuccess] = useState(false);
  const [client, setClient] = useState<clientProps>();
  const [teamList, setTeamList] = useState([{ value: '', label: '' }]);
  const [loading, setLoading] = useState(false);
  const [agentName, setAgentName] = useState('');

  const [agent_id, setAgent_id] = useState(0);
  const [agents, setAgents] = useState([]);
  const [line, setLine] = useState<any>();
  const [plantaoList, setPlantaoList] = useState([
    { value: '', label: '', enterprise_slug: '', supervisor: '' },
  ]);
  const [enterpriseSlug, setEnterpriseSlug] = useState('');
  const [supervisor, setSupervisor] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAgentModal, setSelectedAgentModal] = useState(null);

  const selectedAgent = JSON.parse(sessionStorage.getItem('selectedAgent'));
  const permission = sessionStorage.getItem('permission');
  const [agentPlantao, setAgentPlantao] = useState('');

  const history = useHistory();

  async function onSubmit() {
    if (alreadyRegistered) {
      history.goBack();
      return;
    }

    const data = {
      full_name: client ? client.full_name : fields.full_name,
      cpf: fields.cpf,
      phone_number: fields.phone_number,
      email: client ? client.email : fields.email,
      agent_id: fields.agent_id,
      enterprise_slug: fields.enterprise_slug,
      call_type: fields.call_type,
      group_dashboard:
        selectedAgent.group_dashboard == 'eme_plantao'
          ? fields.group_dashboard
          : selectedAgent.group_dashboard,
      origin:
        selectedAgent.group_dashboard == 'eme_plantao'
          ? 'eme_plantao'
          : 'dashboard',
    };

    const createdCallRes = await api.post('/call/admin/create', data, {
      headers: {
        Authorization: authToken.token,
      },
    });

    if (createdCallRes.status === 201) {
      setSuccess(true);
    }
  }

  const getAllAgents = useCallback(async () => {
    let agentsResponse;
    if (
      permission === 'admin' ||
      permission === 'admin-super' ||
      permission === 'eme_plantao'
    ) {
      agentsResponse = await api.get('/agents', {
        headers: {
          Authorization: authToken.token,
        },
      });
    } else {
      agentsResponse = await api.get('/agents/search/' + selectedAgent.id, {
        headers: {
          Authorization: authToken.token,
        },
      });
    }

    const { agents } = agentsResponse.data;

    return agents;
  }, []);

  useEffect(() => {
    const selectedOption = plantaoList.find(
      (option) => option.label === fields.group_dashboard,
    );
    if (selectedOption) {
      setEnterpriseSlug(selectedOption.enterprise_slug);
      setSupervisor(selectedOption.supervisor);
    } else {
      setEnterpriseSlug('');
    }
  }, [fields.group_dashboard]);

  useEffect(() => {
    async function getListTeam() {
      const resTeam = await api.get(`/agents/teams/getPlantoes`, {
        headers: {
          Authorization: authToken.token,
        },
      });
      setPlantaoList(resTeam.data.response_data);
    }

    getListTeam();
  }, []);

  useEffect(() => {
    async function setAllAgents() {
      const agents = await getAllAgents();

      const filterValidAgents = agents.filter(
        (agent) => agent.permission === 'corretor' && agent.activated,
        // agent.group_dashboard === props.group_dashboard,
      );

      const filterAgentsbyGroup = filterValidAgents.filter((agent) => {
        return selectedAgent.group_dashboard === agent.group_dashboard;
      });

      setAgents(filterAgentsbyGroup);
    }
    async function fetch() {
      await setAllAgents();
    }

    fetch();
  }, [getAllAgents]);

  async function handleFetchRegisteredLead() {
    const groupDashboard =
      permission === 'admin-super' ||
      selectedAgent.group_dashboard === 'eme_plantao'
        ? fields.group_dashboard
        : selectedAgent.group_dashboard;

    if (!groupDashboard) {
      alert('Necessário selecionar o plantão');
      setFields({ ...fields, ['cpf']: '' });
      return;
    }

    if (fields.phone_number) {
      const onlyNumbers = fields.phone_number
        .replace('+55', '')
        .replace(/\D/g, '');
      const existPhoneNumber = await api.get(
        `/client/admin/findPhoneNumber?phone_number=${onlyNumbers}`,
        {
          headers: {
            Authorization: authToken.token,
          },
        },
      );

      if (existPhoneNumber.data.response_data) {
        setAgentName(existPhoneNumber.data.response_data[0].agent_name);
        setAlreadyRegistered(true);
      }
      setLoading(false);
    }

    if (fields.cpf) {
      const cpfFormat = String(fields.cpf).replace(/[^\d]/g, '');
      cpfFormat.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      setLoading(true);
      const existCpf = await api.get(`/client/admin/findCpf?cpf=${cpfFormat}`, {
        headers: {
          Authorization: authToken.token,
        },
      });

      if (existCpf.data.response_data) {
        setAgentName(existCpf.data.response_data[0].agent_name);
        setAlreadyRegistered(true);
        setClient(existCpf.data.response_data[0]);
      }
      setLoading(false);
    }
  }

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleAgentSelection = (event) => {
    const agent = agents.find((a) => a.id === parseInt(event.target.value));
    setSelectedAgentModal(agent);
  };

  const handleTransfer = async () => {
    if (!selectedAgent) {
      alert('Selecione um agente antes de continuar.');
      return;
    }

    const data = {
      full_name: client.full_name,
      cpf: client.cpf,
      phone_number: client.phone_number,
      email: client.email,
      agent_id: selectedAgent.id,
      enterprise_slug: enterpriseSlug,
      call_type: 'visit',
      group_dashboard:
        selectedAgent.group_dashboard === 'eme_plantao'
          ? fields.group_dashboard
          : selectedAgent.group_dashboard,
      origin:
        selectedAgent.group_dashboard === 'eme_plantao'
          ? 'eme_plantao'
          : 'dashboard',
    };

    setLoading(true);

    try {
      const createdCallRes = await api.post('/call/admin/create', data, {
        headers: {
          Authorization: authToken.token,
        },
      });

      if (createdCallRes.status === 201) {
        setLoading(false);
        setAgentPlantao(createdCallRes.data.response_data.agent);
        setSuccess(true);
        closeModal();
      }
    } catch (error) {
      console.error('Erro ao transferir a chamada:', error);
    }
  };

  async function transferToAgent() {
    setIsModalOpen(true);
  }

  async function transferToSupervisor() {
    const data = {
      full_name: client.full_name,
      cpf: client.cpf,
      phone_number: client.phone_number,
      email: client.email,
      agent_id: supervisor,
      enterprise_slug: enterpriseSlug,
      call_type: 'visit',
      group_dashboard:
        selectedAgent.group_dashboard == 'eme_plantao'
          ? fields.group_dashboard
          : selectedAgent.group_dashboard,
      origin:
        selectedAgent.group_dashboard == 'eme_plantao'
          ? 'eme_plantao'
          : 'dashboard',
    };
    if (!supervisor) {
      alert('Supervisor não encontrado');
      return;
    }

    const createdCallRes = await api.post('/call/admin/create', data, {
      headers: {
        Authorization: authToken.token,
      },
    });

    if (createdCallRes.status === 201) {
      setAgentPlantao(createdCallRes.data.response_data.agent);
      setSuccess(true);
    }
  }

  return (
    <>
      <S.Container>
        {loading && (
          <S.LoadingContainer>
            <S.StyledSpinner animation="border" />
            <S.LoadingText>Processando...</S.LoadingText>
          </S.LoadingContainer>
        )}
        <S.Card>
          <S.LogoContainer>
            <ReactSVG src={hmLogo} />
            <h1>
              <span>Engenharia</span>
            </h1>
          </S.LogoContainer>

          {success ? (
            <Success agent_name={agentPlantao} permission={permission} />
          ) : (
            <S.Form>
              <FormikStepper
                initialValues={{
                  cpf: '',
                  phone_number: '',
                  name: '',
                  email: '',
                  agent_id: 0,
                  enterprise_slug: '',
                  call_type: '',
                }}
                onSubmit={onSubmit}
                handleFetchRegisteredLead={handleFetchRegisteredLead}
                alreadyRegistered={alreadyRegistered}
                fields={fields}
                permission={permission}
                transferToAgent={transferToAgent}
                transferToSupervisor={transferToSupervisor}
              >
                <FormikStep label="Dados do lead">
                  <FormContext.Provider
                    value={{
                      fields,
                      setFields,
                    }}
                  >
                    <StepOne
                      alreadyRegistered={alreadyRegistered}
                      agentName={agentName}
                      teamList={teamList}
                      plantaoList={plantaoList}
                      handleFetchRegisteredLead={handleFetchRegisteredLead}
                    />
                  </FormContext.Provider>
                </FormikStep>

                <FormikStep label="Dados do atendimento">
                  <FormContext.Provider
                    value={{
                      fields,
                      setFields,
                    }}
                  >
                    <StepTwo
                      alreadyRegistered={alreadyRegistered}
                      client={client}
                      group_dashboard={fields.group_dashboard}
                      enterprises={enterpriseSlug}
                    />
                  </FormContext.Provider>
                </FormikStep>
              </FormikStepper>
            </S.Form>
          )}
        </S.Card>
        <ToastContainer />
      </S.Container>
      <EstiloModalSelecao show={isModalOpen} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Selecione um Agente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <select
            name="agent"
            value={agent_id}
            required
            onChange={(e) => handleAgentSelection(e)}
            className="plantao-item"
          >
            <option value="">Selecione um Corretor</option>
            {agents.map((agent) => (
              <option key={agent.id} value={agent.id}>
                {agent.full_name}
              </option>
            ))}
          </select>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              marginRight: '1rem',
              backgroundColor: '#d50f30',
              color: 'rgb(255, 255, 255)',
            }}
            onClick={closeModal}
          >
            Cancelar
          </Button>
          <Button
            style={{ backgroundColor: '#30d50f', color: 'rgb(255, 255, 255)' }}
            onClick={handleTransfer}
          >
            Confirmar Transferência
          </Button>
        </Modal.Footer>
        {/* </Modal> */}
      </EstiloModalSelecao>
    </>
  );
}

export interface FormikStepProps
  extends Pick<FormikConfig<FormikValues>, 'validationSchema'> {
  label: string;
  children?: ReactNode | ((props: FormikProps<FormikValues>) => ReactNode);
}

export function FormikStep({ children }: FormikStepProps) {
  return (
    <>
      {typeof children === 'function'
        ? children({} as FormikProps<FormikValues>)
        : children}
    </>
  );
}

export function FormikStepper({
  children,
  alreadyRegistered,
  fields,
  ...props
}: any) {
  const childrenArray = React.Children.toArray(
    children,
  ) as React.ReactElement<FormikStepProps>[];
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];
  const [completed, setCompleted] = useState(false);

  const history = useHistory();

  function isLastStep() {
    return step === childrenArray.length - 1;
  }

  function handleCloseRegisterLeadModal() {
    history.goBack();
  }

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          await props.onSubmit(values, helpers);

          setCompleted(true);
        } else {
          if (step === 0) {
            const { cpf, phone_number } = fields;

            const cpfNumbers = removeSpecialCaracteres(cpf);
            const phoneNumbers =
              removeSpecialCaractersFromPhoneNumber(phone_number);

            if (!cpfValidate(cpfNumbers)) {
              return toast.error('CPF não possui um formato válido.', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }

            if (phoneNumbers.length < 11) {
              return toast.error('Telefone não possui um formato válido.', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }

            setTimeout(async () => {
              await props.handleFetchRegisteredLead();
              setStep((s) => s + 1);
            }, 1000);
          }
        }
      }}
    >
      {({ errors, isSubmitting }) => (
        <Form autoComplete="off">
          {step === 0 && <h2 className="title-1 mb-4">Cadastro</h2>}
          {step === 1 && !alreadyRegistered && (
            <h2 className="title-1 mb-4">Concluir cadastro</h2>
          )}
          <Stepper alternativeLabel activeStep={step}>
            {childrenArray.map((child, index) => (
              <Step
                key={child.props.label}
                completed={step > index || completed}
              >
                <StepLabel></StepLabel>
              </Step>
            ))}
          </Stepper>
          {currentChild}
          <div className="buttons-next">
            {step === 0 && (
              <ComponentToRender
                permission={props.permission}
                alreadyRegistered={alreadyRegistered}
                isSubmitting={isSubmitting}
                handleCloseRegisterLeadModal={handleCloseRegisterLeadModal}
                transferToAgent={props.transferToAgent}
              />
            )}

            {step > 0 && !alreadyRegistered ? (
              <Button
                disabled={isSubmitting}
                variant="contained"
                color="primary"
                onClick={() => setStep((s) => s - 1)}
              >
                Voltar
              </Button>
            ) : null}

            {!alreadyRegistered ? (
              <Button
                startIcon={
                  isSubmitting ? <CircularProgress size="1rem" /> : null
                }
                disabled={isSubmitting}
                variant="contained"
                color="primary"
                type="submit"
                className={step === 1 ? 'red' : 'black'}
              >
                {isSubmitting
                  ? 'Enviando'
                  : isLastStep()
                  ? 'Cadastrar'
                  : 'Próximo'}
              </Button>
            ) : (
              <>
                {props.permission == 'eme_plantao' ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className={'red'}
                    style={{ color: '#FFF' }}
                    onClick={() => props.transferToSupervisor()}
                  >
                    Não
                  </Button>
                ) : (
                  <Button
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={'red'}
                    style={{ color: '#FFF' }}
                  >
                    Fechar
                  </Button>
                )}
              </>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}

const ComponentToRender = (props: any) => {
  if (props.permission === 'eme_plantao' && props.alreadyRegistered) {
    return (
      <Button
        variant="contained"
        color="primary"
        className={'red'}
        style={{ color: '#FFF' }}
        onClick={() => props.transferToAgent()}
      >
        Sim
      </Button>
    );
  } else {
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={() => props.handleCloseRegisterLeadModal()}
      >
        Cancelar
      </Button>
    );
  }
};
